
let $window = $(window);
let $bodyHtml = $("body,html");
let $html = $("html");
let $body = $("body");
let breakPoint1 = 767;

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
  scrollPosi = $(window).scrollTop();
  $body.css({
    position: "fixed",
    width: "100%",
    "z-index": "1",
    top: -scrollPosi,
  });
}

function bodyFixReset() {
  $body.css({
    position: "relative",
    width: "auto",
    top: "0",
  });
  $bodyHtml.scrollTop(scrollPosi);
}

$(document).ready(function () {
  $(window).scroll(function () {
    $('.js_header').css("left", - $(window).scrollLeft());
  });

  $('.js_archor a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    var target = this.hash;
    var $target = $(target);
    if (window.matchMedia('(max-width: 767px)').matches) {
      var h = 50;
    } else {
      var h = 120;
    }
    $('html, body').stop().animate({
      'scrollTop': $target.offset().top - h
    }, 900, 'swing', function () {
      // window.location.hash = target;
    });
  });
});
// click accordion 1
$(".js_block_item").each(function () {
  var parent = $(this).children('.js_block_inner').children('.js_accordion');
  var child = parent.children('.js_accordion_item');
  child.each(function () {
    var childTitle = $(this).children('.js_accordion_title');
    var childDesc = $(this).children('.js_accordion_desc');
    $(childTitle).on("click", function () {
      $(this).toggleClass("is-show");
      $(childDesc).slideToggle();
    });
  });
});

$('.js_navbar').on('click', function (e) {
  if ($(window).width() <= 767) {
    e.preventDefault();
    $('.js_header').toggleClass('is-fixed');
    if ($('.js_header').hasClass('is-fixed')) {
      bodyFix();
    } else {
      bodyFixReset();
    }
    // $('.js_menu_btndown, .c-header__dropdown').removeClass('is-show');
  }
});

$(".js_menu_item.is-submenu").each(function () {
  var link = $(this).children('.c-header__menu__link');
  var child = link.children('.js_menu_btndown');
  $(child).on("click", function (e) {
    if ($(window).width() <= 767) {
      e.preventDefault();
      $(this).toggleClass("is-show");
      // $(this).next().toggleClass("is-show");
      link.next().slideToggle();
    }
  });
});

// scroll to top
var offSetTop = 100;
var $scrollToTopButton = $('.js_scrolltotop');
//Check to see if the window is top if not then display button
$(window).scroll(function () {
  if ($(this).scrollTop() > offSetTop) {
    $scrollToTopButton.fadeIn();
  } else {
    $scrollToTopButton.fadeOut();
  }
});

//Click event to scroll to top
$scrollToTopButton.click(function () {
  $('html, body').animate({ scrollTop: 0 }, 800);
  return false;
});

/* ======================================
  accordion2
====================================== */
$('.js_accordion2').each(function () {
  let $this = $(this);
  $('.js_accordion2_heading', $this).on('click', function () {
    $(this).toggleClass('is-open');
    $('.js_accordion2_info', $this).slideToggle();
  });
});

//===================================================
//  matchHeight
//===================================================
$(window).on("load resize", function () {
  if ($('.js_matchHeight').length > 0) {
    $('.js_matchHeight').matchHeight();
  }
});

